import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container mx-auto p-1 sm:p-8" }
const _hoisted_2 = { class: "bg-primary-50 p-1 rounded shadow-md bg-white sm:p-8" }
const _hoisted_3 = { class: "text-xl font-semibold text-gray-900 mb-4" }
const _hoisted_4 = { class: "text-lg text-gray-600 mb-4" }
const _hoisted_5 = {
  href: "/",
  class: "primary mt-4 inline-block px-8 py-3 text-lg text-white bg-primary-600 rounded-lg hover:bg-primary-700"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.$t('register.success_header')), 1),
      _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('register.success_message')), 1),
      _createElementVNode("a", _hoisted_5, _toDisplayString(_ctx.$t('nav.return_home')), 1)
    ])
  ]))
}